import React from 'react';
import {
	Box,
	Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
	LinearProgress,
    MenuItem,
    Radio,
    RadioGroup
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import {
    Select,
    Switch,
    TextField,
} from 'formik-mui';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import {
	doc,
	getDoc,
} from "firebase/firestore";
import { db } from '../../../firebase-info';
import useLocalStorageState from 'use-local-storage-state'
import { navigate } from "gatsby";
import { GamesContext, AuthContext } from '../../contexts/contexts.js';

const formContainer = {
	width: '100%',
	marginTop: '32px',
};
const maxInputWidth = {
    minWidth: '100%'
};

const TaxEvasionGameForm = ({handleOnChange }) => {
	const { t } = useTranslation();
    const gamesInfo = React.useContext(GamesContext);
    const userInfo = React.useContext(AuthContext);
    const [experiment, setExperiment] = useLocalStorageState("experiment");
    const [gamePosition, setGamePosition] = useLocalStorageState("gamePosition", {defaultValue: undefined});
    const [started, setStarted] = React.useState(false);

	const handleSubmit = async (values) => {
		try {
            if (gamePosition !== undefined) {
                const game = {...experiment.games[gamePosition], params: {...values}};

                experiment.games[gamePosition] = game;
            } else {
                const gameData = gamesInfo.filter((game) => game.id === "taxevasion");
                const game = {...gameData[0], params: {...values}};

                const gamesLikeThis = experiment.games.filter((g) => (game.id === g.id))
                const maxInstance = Math.max(gamesLikeThis.map((g) => g.instance))
                if(!maxInstance){
                    game.instance = 1;
                }
                else{
                    game.instance = maxInstance+1;
                }
                game["listId"] = `${game.id}_${game.instance}`;

                experiment.games.push(game);
            }
            
            setGamePosition(undefined);
            setExperiment(experiment);

		} catch(e) {
            console.log(e);
            //we should handle the error
		}
	}

	const validate = values => {
		const errors = {};
	 
		if (values.timeLimit && !values.time) {
			errors.time = t('Required');
        }
        if (values.timeLimit && values.time < 1) {
            errors.time = t('Must be bigger than 1');
        } 
		if (!values.reward) {
			errors.reward = t('Required');
		}
        if (values.reward < 0) {
            errors.reward = t('Must be bigger than 0');
        }

		return errors;
	  };

    const goBack = () => {
        setGamePosition(undefined);
        if(gamePosition !== undefined){
            navigate("/checkoutExperiment/");
        }
        else{
            navigate(-1);
        }
    }

	return(
        <Layout>
            <Formik
                initialValues={
                    (gamePosition !== undefined && experiment?.games[gamePosition].params) || {
                    timeLimit: false,
                    time: null,
                    isSocial: false,
                    reward: 1,
                    currency: '$',
                    taxSelection: '5',
                }}
                style={formContainer}
                onSubmit={async (values, actions) => { 
                    await handleSubmit(values);
                    actions.setSubmitting = false;
                    goBack();
                }}
                validate={(values) => { return validate(values) }}
            >
                {({values, submitForm, resetForm, isSubmitting, setFieldValue}) => (
                    <Form onChange={handleOnChange}>
                        <Box m={1} textAlign={'left'}>
                            <FormControlLabel
                                control={
                                    <Field component={Switch} type="checkbox" name="isSocial" />
                                }
                                label={<Trans>Mark the experiment as social enterprise type. (Leave it unchecked for comercial enterprise)</Trans>}
                            />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Box m={1} textAlign={'left'}>
                                    <FormControlLabel
                                        control={
                                            <Field component={Switch} type="checkbox" name="timeLimit" />
                                        }
                                        label={<Trans>Add time limit</Trans>}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                {values.timeLimit ?
                                    <Box m={1} textAlign={'left'}>
                                        <Field
                                            component={TextField}
                                            style={maxInputWidth}
                                            name={'time'}
                                            type="number"
                                            label={t("Add time for participant (in minutes)")}
                                        />
                                    </Box>
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                        <Box m={1} textAlign={'left'}>
                            <Field
                                component={TextField}
                                style={maxInputWidth}
                                name={'reward'}
                                type="number"
                                label={t("Add reward per correct word")}
                            />
                        </Box>
                        <Box m={1} textAlign={'left'}>
                            <Field
                                formControl={{ sx: { width: '100%', textAlign: 'left'}}}
                                style={maxInputWidth}
                                component={TextField}
                                name={'currency'}
                                type="text"
                                label={t("Add currency per correct word")}
                            />
                        </Box>
                        <Box>
                        <FormControl component="fieldset">
                            <FormLabel id="tax-group"><Trans>Select tax</Trans></FormLabel>
                            <RadioGroup
                                aria-labelledby="tax-group"
                                name={"taxSelection"}
                                row
                                value={values.taxSelection.toString()} onChange={(event) => {
                                    setFieldValue("taxSelection", event.currentTarget.value);
                                }}
                            >
                                <FormControlLabel
                                    name={"taxSelection"}
                                    control={<Radio />}
                                    label={t("5 %")}
                                    value={"5"}
                                />
                                <FormControlLabel
                                    name={"taxSelection"}
                                    control={<Radio />}
                                    label={t("30%")}
                                    value={"30"}
                                />
                                <FormControlLabel
                                    name={"taxSelection"}
                                    control={<Radio />}
                                    label={t("50%")}
                                    value={"50"}
                                />
                                <FormControlLabel
                                    name={"taxSelection"}
                                    control={<Radio />}
                                    label={t("70%")}
                                    value={"70"}
                                />
                                <FormControlLabel
                                    name={"taxSelection"}
                                    control={<Radio />}
                                    label={t("95%")}
                                    value={"95"}
                                />
                                <FormControlLabel
                                    name={"taxSelection"}
                                    control={<Radio />}
                                    label={t("Random")}
                                    value={"random"}
                                />
                            </RadioGroup>
                        </FormControl>
                </Box>
                        {isSubmitting && <LinearProgress />}
                        <Box margin={1}>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={isSubmitting || !userInfo?.userData.gamePermissions.taxevasion.usable}
                                onClick={() => {
                                    submitForm();
                                }}
                            >
                                {gamePosition !== undefined ? 
                                    <Trans>Modify game parameters</Trans>
                                :
                                    <Trans>Add game to experiment</Trans>
                                }
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    resetForm();
                                }}
                            >
                                <Trans>Reset values</Trans>
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="error"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    goBack();
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Layout>
	);
}

export default TaxEvasionGameForm;